import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Privacy = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Privacy" />
      <h1>Privacy Policy</h1>
      <p>
        We are a community of renters who want to make the world a better place
        by going solar. We are working to make solar more accessible to renters
        and to help renters save money on their energy bills.
      </p>
      {/* outline we never share data */}

      <h1>
        We never share your data with anyone. We don't even have access to it.
      </h1>
      <p>
        We use a third party service called Formspree to collect your email
        address. Formspree is a service that allows you to collect emails from
        your website without having to build a backend. Formspree is a trusted
        service that has been around for a long time. They have a great privacy
        policy that you can read here.
      </p>
      <p>
        We use a third party service called ReCaptcha to prevent spam. ReCaptcha
        is a service that allows you to prevent spam on your website without
        having to build a backend. ReCaptcha is a trusted service that has been
        around for a long time. They have a great privacy policy that you can
        read here.
      </p>
      <p>
        We use a third party service called Google Analytics to collect
        information about how people use our website. Google Analytics is a
        service that allows you to collect information about how people use your
        website without having to build a backend. Google Analytics is a trusted
        service that has been around for a long time. They have a great privacy
        policy that you can read here.
      </p>
      <p>
        We use a third party service called Google Ads to collect information
        about how people use our website. Google Ads is a service that allows
        you to collect information about how people use your website without
        having to build a backend. Google Ads is a trusted service that has been
        around for a long time. They have a great privacy policy that you can
        read here.
      </p>
      <p>
        We use a third party service called Google Tag Manager to collect
        information about how people use our website. Google Tag Manager is a
        service that allows you to collect information about how people use your
        website without having to build a backend. Google Tag Manager is a
        trusted service that has been around for a long time. They have a great
        privacy policy that you can read here.
      </p>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Privacy
